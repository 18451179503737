class SendByEmail {
  constructor(obj) {
    this.parentSelector = obj.parentSelector;
    this.arrInput = document.querySelectorAll(obj.arrInput);
    this.btnSend = document.querySelector(obj.btnSend);
    this.thanks_btn = document.querySelector(obj.close_block_btn);
    this.thanks_btn.addEventListener(
      "click",
      this.hide_thanks_block.bind(this)
    );
    this.block_thanks = obj.block_thanks;
    this.btnSend.style.opacity = "0.6";

    this.arrHideElements = [];
    for (let i = 0; i < obj.arrHideElements.length; i++) {
      this.arrHideElements.push(obj.arrHideElements[i]);
    }

    for (let i = 0; i < this.arrInput.length; i++) {
      this.arrInput[i].addEventListener(
        "input",
        this.valid_form_write_us.bind(this)
      );
    }
    this.btnSend.addEventListener("click", this.send_mail.bind(this));

    $("body").on(
      "click",
      obj.parentSelector + " .messageSuccess__cross",
      function () {
        if (!$("html").hasClass("ecommerce-popup")) {
          return;
        }
        $("html").toggleClass("ecommerce-popup");
      }
    );
  }

  valid_form_write_us() {
    let error = [];
    for (let i = 0; i < this.arrInput.length; i++) {
      if (
        this.arrInput[i].hasAttribute("required") &&
        this.arrInput[i].value.trim().length < 1
      ) {
        if (this.arrInput[i].previousElementSibling !== null) {
          error.push(this.arrInput[i].previousElementSibling.textContent);
        }
      }
    }

    if (error.length == 0) {
      this.btnSend.removeAttribute("disabled");
      this.btnSend.style.opacity = "1";
    } else if (error.length > 0) {
      this.btnSend.setAttribute("disabled", "disabled");
      this.btnSend.style.opacity = "0.6";
    }
  }

  send_mail(e) {
    e.preventDefault();
    let form = document.querySelector(this.parentSelector + ".form");
    let data = new FormData(form);

    console.log(data.get("form"), form);

    fetch("/wp-admin/admin-ajax.php?action=sendEmail", {
      method: "POST",
      body: data,
    })
      .then((response) => {
        if (response.status != 200) {
          return Promise.reject(response);
        } else {
          return response;
        }

        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          this.show_thanks_block().bind(this);
          console.log(response);
        }
        return response;
      })
      .catch((response) => {
        console.log("Ошибка Catch");
        console.log(response);
      });
  }

  show_thanks_block() {
    if (this.arrHideElements.length > 0) {
      for (let i = 0; i < this.arrHideElements.length; i++) {
        document.querySelector(this.arrHideElements[i]).style.display = "none";
      }
    }
    document.querySelector(this.block_thanks[0]).style.display =
      this.block_thanks[1];
    for (let el of this.arrInput) {
      el.value = "";
    }
    //e.target.reset();
  }

  // Возвращаем всё на место
  hide_thanks_block(e) {
    e ? e.preventDefault() : "";
    if (this.arrHideElements.length > 0) {
      for (let i = 0; i < this.arrHideElements.length; i++) {
        document.querySelector(this.arrHideElements[i]).style.display = "";
      }
    }
    document.querySelector(this.block_thanks[0]).style.display = "";
  }
}

class ValidationForm {
  constructor(formData) {
    this.debounceTimeOut = 0;
    this.arrErrors = [];
    this.input__name = formData.input__name;
    this.input__email = formData.input__email;
    this.input__phone = formData.input__phone;
    this.formTag = formData.formTag;
    this.label__name = formData.label__name;
    this.label__email = formData.label__email;
    this.label__phone = formData.label__phone;
    this.form__errors = this.formTag.querySelectorAll(".form__errors");
    this.form__input = this.formTag.querySelectorAll(".form__input");
    this.form__btn = formData.form__btn;
    this.buttonTextSend = formData.buttonTextSend || "Отправить сообщение";
    this.buttonTextSent = formData.buttonTextSent || "Отправлено";

    this.regPhone =
      /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/; // телефон
    this.regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Email
    this.regName = /^[a-zA-Zа-яА-Яё ,.'-]+$/i; // Имя
    this.libErrorsText = {
      phoneFormat: "Указанный номер не соответствует формату!",
      phoneEmpty: "Не заполнено поле, - номера телефона!",
      emailFormat: "Указанный email не соответствует формату!",
      nameEmpty: "Не верно заполнено поле, - Имя!",
      emptyField: "Не заполнено поле, - ",
    };

    this.form__input.forEach((item) => {
      item.addEventListener("input", () => {
        clearTimeout(this.debounceTimeOut);
        this.debounceTimeOut = setTimeout(() => {
          this.validateForm();
        }, 500);
      });
    });

    this.formTag.addEventListener("submit", (e) => {
      e.preventDefault();
      this.validateFormSubmit();
    });
  }

  validateFormController(regularExpression, field, label, errorText) {
    if (
      !this.regexMatchCheck(regularExpression, field.value.trim()) &&
      field.value.trim().length > 2
    ) {
      if (!this.arrErrors.find((item) => item.place === field.id)) {
        this.arrErrors.push({ place: field.id, textError: errorText });
      }
      this.switchBtn(false);
      this.form__btn.textContent = this.buttonTextSend;
    } else {
      this.switchBtn(true);
      this.deleteElArrErrors(field);
    }
    this.showErrors();
  }

  validateForm() {
    this.validateFormController(
      this.regName,
      this.input__name,
      this.label__name,
      this.libErrorsText.nameEmpty
    );
    this.validateFormController(
      this.regEmail,
      this.input__email,
      this.label__email,
      this.libErrorsText.emailFormat
    );
    this.validateFormController(
      this.regPhone,
      this.input__phone,
      this.label__phone,
      this.libErrorsText.phoneFormat
    );
  }

  validateFormSubmit() {
    this.form__input.forEach((item) => {
      if (this.testForEmptiness(item.value.trim())) {
        this.arrErrors.push({
          place: item.id,
          textError:
            this.libErrorsText.emptyField + item.getAttribute("placeholder"),
        });
        this.switchBtn(false);
        this.form__btn.textContent = this.buttonTextSend;
      } else {
        // Валидация пройдена можно отправлять
        this.form__btn.textContent = this.buttonTextSent;
        this.switchBtn(false);
        return true;
      }
      this.showErrors();
    });
  }

  deleteElArrErrors(el) {
    this.arrErrors.forEach((item, index, arr) => {
      if (item.place === el.id) {
        arr.splice(index, 1);
      }
    });
  }

  showErrors() {
    this.form__errors.forEach((item) => (item.innerHTML = ""));
    if (this.arrErrors.length > 0) {
      this.arrErrors.forEach((item) => {
        let ul = document.createElement("Ul");
        let li = document.createElement("LI");
        li.textContent = item.textError;
        ul.appendChild(li);
        let error_place = document.querySelector("#" + item.place).parentNode;
        error_place.querySelector(".form__errors").innerHTML = ul.innerHTML;
      });
    } else {
      this.form__errors.forEach((item) => (item.innerHTML = ""));
    }
  }

  testForEmptiness(str) {
    return str.length === 0;
  }

  regexMatchCheck(reg, inp) {
    return reg.test(inp);
  }

  switchBtn(status) {
    status
      ? (this.form__btn.disabled = false)
      : (this.form__btn.disabled = true);
  }
}

window.addEventListener("load", () => {
  let screen_width = window.innerWidth;
  if (screen_width < 1030) {
    initSlider();
    initSliderPortfolio();
    initSliderEcommerce();
  }
  initSliderPortfolioListECommerce();
  initSliderPortfolioListGamingIndustries();
  initSliderPortfolioListFintechInsureTech();
  //initSliderIndustries()
  initSliderTestimonials();
  initSliderNewTestimonials();
  initSliderWeAreNomadicSoft();
  initSliderOurTeam();
  initSliderOurPosts();

  window.addEventListener("resize", (e) => {
    screen_width = window.innerWidth;
    if (screen_width < 1030) {
      initSlider();
      initSliderPortfolio();
      initSliderEcommerce();
    }
    //initSliderIndustries()
    // initSliderTestimonials();
    // initSliderNewTestimonials();
    // initSliderWeAreNomadicSoft();
    // initSliderOurTeam();
    // initSliderOurPosts();
  });

  if (screen_width < 960) {
    //console.log(screen_width)
    initSlider();
  }

  window.addEventListener("resize", (e) => {
    screen_width = window.innerWidth;
    if (screen_width < 960) {
      initSlider();
    }
  });

  initSlider();

  setTimeout(() => {
    initSlider();
  }, 1000);

  function initSlider() {
    //console.log('.card-technology__wrap-mobile')
    if (document.querySelector(".card-technology__wrap-mobile")) {
      let slider = tns({
        container: ".card-technology__wrap-mobile",
        items: 2,
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: "bottom",
        mouseDrag: true,
      });
      slider = slider.rebuild();
    }
  }

  function initSliderPortfolio() {
    if (document.querySelector(".img-examples__list")) {
      tns({
        container: ".img-examples__list",
        items: 2,
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: "bottom",
        mouseDrag: true,
      });
    }
  }

  function initSliderPortfolioListECommerce() {
    if (document.querySelector(".portfolio-list__slider_E-commerce")) {
      tns({
        container: ".portfolio-list__slider_E-commerce",
        responsive: {
          450: { items: 1 },
          700: { items: 2 },
          1000: { items: 3 },
        },
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: "bottom",
        mouseDrag: true,
      });
    }
  }

  function initSliderPortfolioListGamingIndustries() {
    if (document.querySelector(".portfolio-list__slider_GAMING_INDUSTRIES")) {
      tns({
        container: ".portfolio-list__slider_GAMING_INDUSTRIES",
        responsive: {
          450: { items: 1 },
          700: { items: 2 },
          1000: { items: 3 },
        },
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: "bottom",
        mouseDrag: true,
      });
    }
  }

  function initSliderPortfolioListFintechInsureTech() {
    if (
      document.querySelector(".portfolio-list__slider_Fintech_\\&_InsureTech")
    ) {
      tns({
        container: ".portfolio-list__slider_Fintech_\\&_InsureTech",
        responsive: {
          450: { items: 1 },
          700: { items: 2 },
          1000: { items: 3 },
        },
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: "bottom",
        mouseDrag: true,
      });
    }
  }

  // function initSliderIndustries() {
  //     if(document.querySelector('.portfolio-list')) {
  //         tns({
  //             "container": '.portfolio-list',
  //             "responsive": {
  //                 "450": { "items": 1 },
  //                 "700": { "items": 2 },
  //                 "1000": { "items": 3 }
  //             },
  //             "rewind": true,
  //             "speed": 400,
  //             "swipeAngle": false,
  //             "controlsPosition": "bottom",
  //             "navPosition": "bottom",
  //             "mouseDrag": true
  //         });
  //     }
  // }

  function initSliderEcommerce() {
    if (document.querySelector(".ecommerce-list-img")) {
      tns({
        container: ".ecommerce-list-img",
        items: 1,
        gutter: 10,
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: "bottom",
        mouseDrag: true,
      });
    }
  }

  function initSliderTestimonials() {
    if (document.querySelector(".testimonials-list")) {
      tns({
        container: ".testimonials-list",
        items: 1,
        gutter: 0,
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: false,
        mouseDrag: true,
      });
    }
  }

  function initSliderNewTestimonials() {
    if (document.querySelector(".new-testimonials-list")) {
      tns({
        container: ".new-testimonials-list",
        items: 1,
        gutter: 0,
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: false,
        nav: true,
        mouseDrag: true,
        responsive: {
          321: {
            items: 1,
          },
          960: {
            items: 2,
          },
        },
      });
    }
  }

  function initSliderWeAreNomadicSoft() {
    if (document.querySelector(".we-are-nomadicsoft__ul")) {
      tns({
        container: ".we-are-nomadicsoft__ul",
        items: 1,
        gutter: 0,
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: false,
        mouseDrag: true,
        responsive: {
          321: {
            items: 1,
          },
          380: {
            items: 2,
          },
          600: {
            items: 3,
          },
          960: {
            items: 4,
          },
          1200: {
            items: 5,
          },
        },
      });
    }
  }

  function initSliderOurTeam() {
    if (document.querySelector(".our-team__ul")) {
      tns({
        container: ".our-team__ul",
        items: 1,
        gutter: 0,
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: false,
        mouseDrag: true,
        responsive: {
          321: {
            items: 1,
          },
          600: {
            items: 2,
          },
          720: {
            items: 3,
          },
          960: {
            items: 4,
          },
        },
      });
    }
  }

  function initTabsOurPortfolio() {
    // Получаем список всех табов
    const tabs = document.querySelectorAll(".our-portfolio__tab");

    tabs.forEach((tab) => {
      // Добавляем обработчик клика для каждого таба
      tab.addEventListener("click", () => {
        // Получаем номер текущего таба (tab--1, tab--2 и т.д.)
        const currentTabNumber = tab.dataset.tabNumber;

        // Убираем класс active у всех табов
        tabs.forEach((t) => t.classList.remove("active"));

        // Добавляем класс active только к текущему табу
        tab.classList.add("active");

        // Получаем список всех контентов табов
        const tabContents = document.querySelectorAll(
          ".our-portfolio__tab-content"
        );

        tabContents.forEach((content) => {
          // Скрываем все контенты
          content.style.display = "none";

          // Отображаем контент только для выбранного таба
          if (content.dataset.tabContentNumber === currentTabNumber) {
            content.style.display = "flex";
          }
        });
      });
    });
  }

  initTabsOurPortfolio();

  function initSliderOurPosts() {
    if (document.querySelector(".our-posts-list")) {
      tns({
        container: ".our-posts-list",
        responsive: {
          450: { items: 1 },
          700: { items: 2 },
          1000: { items: 3 },
        },
        gutter: 20,
        rewind: true,
        speed: 400,
        swipeAngle: false,
        controlsPosition: "bottom",
        navPosition: "bottom",
        mouseDrag: true,
      });
    }
  }

  if (document.querySelector(".clients__container")) {
    tns({
      container: ".clients__container",
      items: 7,
      rewind: true,
      speed: 400,
      swipeAngle: false,
      controlsPosition: "bottom",
      navPosition: "bottom",
      mouseDrag: true,
      autoplay: true,
      autoplayButtonOutput: false,
    });
  }
});

// $(function () {
//     $('input[name="form__phone"]').phonecode({
//         preferCo: 'en'
//     });
// });

$(function () {
  $(".ecommerce-popup").on("click", function (e) {
    if (e.target !== this) return;

    $("html").toggleClass("ecommerce-popup");
  });
});

window.addEventListener("load", () => {
  $("header").on("click", "ul a.navbar-list__link", function (event) {
    if ($(this).attr("href") != "#section__form") {
      return;
    }
    event.preventDefault();

    if ($("#section__form").length > 0) {
      window.location.href = window.location.href + "#section__form";
      return;
    }

    if ($(".ecommerce-popup").length > 0) {
      $("html").toggleClass("ecommerce-popup");
    }
  });
});

window.addEventListener("load", () => {
  function contactForm(parentSelector) {
    if ($(parentSelector).length < 1) {
      return;
    }

    console.log("sending... 2.0");
    // Валидация формы
    let objForClassFrom = {
      input__name: document.querySelector(parentSelector + "#first_name"),
      input__email: document.querySelector(parentSelector + "#email"),
      input__phone: document.querySelector(parentSelector + "#phone"),
      formTag: document.querySelector(parentSelector + ".form"),
      label__name: document.querySelector(
        parentSelector + 'label[for="first_name"]'
      ),
      label__email: document.querySelector(
        parentSelector + 'label[for="email"]'
      ),
      label__phone: document.querySelector(
        parentSelector + 'label[for="phone"]'
      ),
      form__btn: document.querySelector(parentSelector + ".form__btn"),
      buttonTextSend: "Send",
      buttonTextSent: "Sent",
    };
    let resultValidationForm = new ValidationForm(objForClassFrom);

    // Send E-mail fetch
    let dataByEmail = {
      parentSelector: parentSelector,
      arrInput: parentSelector + ".field", // передача массива с инпутами
      btnSend: parentSelector + ".form__btn", // кнопка отправить
      arrHideElements: [], // массив элементов которые нужно скрыть, что бы показать блок спасибо
      block_thanks: [
        parentSelector + ".contact__form .messageSuccess",
        "block",
      ], // блок спасибо, дисплэй который покажет его
      block_error: [
        parentSelector + ".contact__form .messageNotSuccess",
        "block",
      ], // блок спасибо, дисплэй который покажет его
      close_block_btn: [
        parentSelector + ".contact__form .messageSuccess__closeBTN",
        ".contact__form .messageNotSuccess__closeBTN",
      ], // Кнопки с крестиком, которые уберут блок "спасибо"
      text_error: parentSelector + ".contact__form .text-error",
      loading_block: parentSelector + ".contact__form .loadingio-eclipse",
    };
    let SendByEmailObj = new SendByEmail(dataByEmail);

    // В случае если валидация не прошла, и ошибка не вывелась,
    // воспользуюсь методом вывода ошибки от класса SendByEmail
    if (resultValidationForm.arrErrors.length > 0) {
      SendByEmailObj.show_result_block(".contact__form .messageNotSuccess");
    }
  }

  contactForm(".ecommerce-popup__wrap ");

  window.addEventListener("popstate", function (event) {
    var hash = window.location.hash.substr(1);
    console.log("hash:" + hash);
    if (hash && hash == "section__form") {
      event.preventDefault();
      if ($(".ecommerce-popup").length > 0) {
        $("html").addClass("ecommerce-popup");
      }
    }
  });
});

window.addEventListener("load", () => {
  // Получаем все изображения на странице
  let images = document.querySelectorAll("img");

  // Проходимся по каждому изображению и проверяем наличие и содержимое атрибута alt
  for (let i = 0; i < images.length; i++) {
    let img = images[i];
    let altAttributeValue = img.getAttribute("alt");
    if (altAttributeValue === null || altAttributeValue.trim() === "" || !img.hasAttribute('alt')) {
      // Если атрибут alt отсутствует, пустой или содержит только пробелы, устанавливаем текст в формате "image post N"
      img.setAttribute("alt", `image post ${i + 1}`);
    }
  }
});

window.addEventListener("load", () => {
  // Получаем все элементы <h1>
  let h1Elements = document.querySelectorAll('h1');

  // Проходим по каждому элементу <h1> кроме первого
  for (let i = 1; i < h1Elements.length; i++) {
      // Создаем новый элемент <h2>
      let h2Element = document.createElement('h2');
      
      // Копируем все содержимое из <h1> в <h2>
      while (h1Elements[i].firstChild) {
          h2Element.appendChild(h1Elements[i].firstChild);
      }

      // Заменяем <h1> на <h2>
      h1Elements[i].parentNode.replaceChild(h2Element, h1Elements[i]);
  }
});
