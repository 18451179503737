window.addEventListener('load', () => {
    const navbar_list = document.querySelector('.navbar-list')
    const burger_close = document.querySelector('.burger_close')
    const line1 = document.querySelector('.line1')
    const line2 = document.querySelector('.line2')
    const line3 = document.querySelector('.line3')
    let varDebounce = 0;

    burger_close.addEventListener('click', view_menu)

    function view_menu() {
        if (window.getComputedStyle(navbar_list).display === 'none') {
            show_menu()
        } else {
            hide_menu()
        }
    }

    function show_menu () {
        navbar_list.style.display = 'block'
        line1.classList.add('close_line1')
        line2.classList.add('close_line2')
        line3.classList.add('close_line3')
    }

    function hide_menu () {
        navbar_list.style.display = 'none'
        line1.classList.remove('close_line1')
        line2.classList.remove('close_line2')
        line3.classList.remove('close_line3')
    }

    let screen_width = window.innerWidth
    window.addEventListener('resize', (e) => {
        screen_width = window.innerWidth
        fix_view_menu()
    })

    function fix_view_menu() {
        clearTimeout(varDebounce)
        varDebounce = setTimeout(() => {
            if (screen_width > 1030) {
                navbar_list.style.display = 'flex'
            } else {
                if (window.getComputedStyle(navbar_list).display === 'flex') {
                    hide_menu()
                }
            }
        }, 20)
    }
});