class Card3D {
  constructor(obj) {
    this.varDebounce = 0; // ТаймАут срабатывания всей анимации
    this.сountdownAnim = 0; // ТаймАут срабатывания всей анимации
    this.countCoins = 0; // ТаймАут срабатывания всей анимации
    this.card = document.querySelector(obj.card); // Анимируемый блок
    this.card__container = document.querySelector(obj.card__container); // Блок контейнер
    this.coins = document.querySelectorAll(obj.coins); // Монеты
    this.tiltAngle = obj.tiltAngle || 10; // угол отклонения в градусах
    this.card__container.addEventListener(
      "mousemove",
      this.mainAnimate.bind(this)
    );
  }

  clearTagStyle(el) {
    el.forEach((item) => item.removeAttribute("style"));
  }

  mainAnimate(e) {
    clearTimeout(this.varDebounce);
    clearTimeout(this.сountdownAnim);
    this.varDebounce = setTimeout(() => {
      let rect = e.target.getBoundingClientRect();
      let offset_x = e.offsetX || e.layerX;
      let offset_y = e.offsetY || e.layerY;
      let x = (100 * offset_x) / rect.width;
      let y = (100 * offset_y) / rect.height;

      if (x < 50 && y < 50) {
        // Левый верхний угол
        this.elementDirectionalShift(this.tiltAngle, -this.tiltAngle, -8, 10);
      } else if (x > 50 && y < 50) {
        // Правый верхний угол
        this.elementDirectionalShift(this.tiltAngle, this.tiltAngle, -8, -10);
      } else if (x < 50 && y > 50) {
        // Левый нижний угол
        this.elementDirectionalShift(-this.tiltAngle, -this.tiltAngle, 8, -10);
      } else if (x > 50 && y > 50) {
        // Правый нижний угол
        this.elementDirectionalShift(-this.tiltAngle, this.tiltAngle, 8, 10);
      }
    }, 1);

    this.сountdownAnim = setTimeout(() => {
      this.clearTagStyle([this.card]);
    }, 2000);
  }

  elementDirectionalShift(x, y) {
    this.card.style.transform = `perspective(1200px) rotateX(${x}deg) rotateY(${y}deg) translateZ(0)`;
  }
}

window.addEventListener("load", () => {
  let screen_width = window.innerWidth;
  if (screen_width > 1030) {
    animCard();
  } else {
    return false;
  }

  window.addEventListener("resize", (e) => {
    screen_width = window.innerWidth;
    if (screen_width > 1030) {
      if (document.querySelector(".php-card")) animCard();
    } else {
      return false;
    }
  });

  function animCard() {
    if (document.querySelector(".php-card")) {
      let obg_card1 = {
        card: ".php-card", // Анимируемый блок
        card__container: ".php-card", // Блок контейнер
        tiltAngle: 10, // угол отклонения в градусах
      };
      new Card3D(obg_card1);
    }

    if (document.querySelector(".java-card")) {
      let obg_card2 = {
        card: ".java-card",
        card__container: ".java-card",
        tiltAngle: 10,
      };
      new Card3D(obg_card2);
    }

    if (document.querySelector(".python-card")) {
      let obg_card3 = {
        card: ".python-card",
        card__container: ".python-card",
        tiltAngle: 10,
      };
      new Card3D(obg_card3);
    }

    if (document.querySelector(".javascript-card")) {
      let obg_card4 = {
        card: ".javascript-card",
        card__container: ".javascript-card",
        tiltAngle: 10,
      };
      new Card3D(obg_card4);
    }

    if (document.querySelector(".wordpress-card")) {
      let obg_card5 = {
        card: ".wordpress-card",
        card__container: ".wordpress-card",
        tiltAngle: 10,
      };
      new Card3D(obg_card5);
    }

    if (document.querySelector(".first-screen__image")) {
      let obg_card6 = {
        card: ".first-screen__image",
        card__container: ".first-screen",
        tiltAngle: 10,
      };
      new Card3D(obg_card6);
    }

    if (document.querySelector(".article-header__image")) {
      let obg_card6 = {
        card: ".article-header__image",
        card__container: ".article-header",
        tiltAngle: 10,
      };
      new Card3D(obg_card6);
    }
  }
});
