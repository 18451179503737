window.addEventListener('load', () => {
    const faq__switch = document.querySelectorAll('.faq__switch')
    const faq_item__p = document.querySelectorAll('.faq-item__p')
    const faq_item__h3 = document.querySelectorAll('.faq-item__h3')

    for (let el of faq__switch) {
        el.addEventListener('click', show_content)
    }

    for (let el of faq_item__h3) {
        el.addEventListener('click', show_content)
    }

    function show_content(e) {
        if (e.target.parentNode.lastElementChild.classList.contains('faq-item__p_active')) {
            cleanActiveClass()
        } else {
            cleanActiveClass()
            e.target.parentNode.firstElementChild.classList.add('faq__switch_active')
            e.target.parentNode.lastElementChild.classList.add('faq-item__p_active')
        }
    }

    function cleanActiveClass() {
        for (let el of faq__switch) {
            el.classList.remove('faq__switch_active')
        }
        for (let el of faq_item__p) {
            el.classList.remove('faq-item__p_active')
        }
    }
})