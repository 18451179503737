window.addEventListener('load', () => {
    let field = document.querySelectorAll('.field')
    for (let fieldElement of field) {
        fieldElement.addEventListener('focus', labelUp)

        if(fieldElement.value.length > 0) {
            fieldElement.previousElementSibling.classList.add('label_up-active')
        }
    }

    function labelUp(e) {
        if (e.target.previousElementSibling.classList.contains('label_up')) {
            e.target.previousElementSibling.classList.add('label_up-active')
        }

        if (e.target.parentNode.parentNode.firstElementChild.classList.contains('label_up')) {
            e.target.parentNode.parentNode.firstElementChild.style.opacity = 0
        }
    }

    /* Validation field */
    const email = document.getElementById("email")
    email.addEventListener("input", function (event) {
        if (email.validity.typeMismatch) {
            email.setCustomValidity("Please check that you have entered your email correctly!")
        } else {
            email.setCustomValidity("")
        }
    })

    const form  = document.querySelector('.form');
    const emailError = document.querySelector('.emailError');

    email.addEventListener('input', function (event) {
        if (email.validity.valid) {
            emailError.textContent = '';
            emailError.className = 'error';
        } else {
            showError();
        }
    });

    form.addEventListener('submit', function (event) {
        if(!email.validity.valid) {
            showError();
            event.preventDefault();
        }
    });

    function showError() {
        if(email.validity.valueMissing) {
            emailError.textContent = 'You need to enter an e-mail address.';
        } else if(email.validity.typeMismatch) {
            emailError.textContent = 'Entered value needs to be an e-mail address.';
        } else if(email.validity.tooShort) {
            emailError.textContent = `Email should be at least ${ email.minLength } characters; you entered ${ email.value.length }.`;
        }
        emailError.className = 'error active';
    }
})